.editor {
  &__invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  &__status-icon {
    float: left;
  }
  &__side-pane {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 56px);
    &__header {
      flex: 0 0 50px;
    }

    &__body {
      flex: 1;
      overflow: auto;

      > div {
        width: calc(100% - 20px);
        margin-left: 10px;
      }
    }

    &__footer {
      flex: 0 0 60px;
    }
  }
}

.popover-body {
  padding: 0 0;
}

.circle-picker div[title='#ffffff'] {
  border: 1px solid #cccccc;
}

.advanced-controls-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.banner-switch-container {
  width: 100%;
  display: flex;
  align-items: center;
}
