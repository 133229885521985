.model-viewer {
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  position: relative;
  flex-direction: column;
  min-height: 0;
  &__model {
    width: 100vw;
    height: 85vh;
    height: calc(var(--vh, 1vh) * 85);
    position: relative;
    &--fullscreen {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &__info {
    width: 100vw;
    height: 15vh;
    height: calc(var(--vh, 1vh) * 15);
    z-index: 1000;
    box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.25);
  }
  &__close {
    position: fixed;
    top: 16px;
    right: 16px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 20px;
    border: 1px solid #333333;
    line-height: 20px;
    text-align: center;
    color: #333333;
    font-size: 12px;
    box-sizing: content-box;
    cursor: pointer;
  }
  &__full-screen-button {
    bottom: 40px;
    right: 40px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  &__view-in-ar {
    position: absolute;
    bottom: 40px;
    left: calc(50vw - 90px);
    width: 180px;
    display: flex;
    align-items: center;
    font-size: 14px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
    &-text {
      display: inline-block;
      flex: 1;
    }
    &-icon {
      height: 25px;
      width: 25px;
      animation: animateHeart 2.5s infinite;
    }
  }

  .ar-modal {
    .modal-dialog.modal-lg {
      padding: 0;
      margin: 0;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: none;
    }
    .modal-content {
      height: 75%;
      width: 40%;
      border: none;
      min-width: 420px;
      .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 70%;
        justify-content: space-evenly;
        border: none;
      }
    }

    .ImageCard {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 10px;
    }

    .QrImage {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .QrLogo {
      position: absolute;
      width: 40px;
      height: 45px;
    }

    .shareUrl {
      width: 100%;
      height: 50px;
      background-color: #f0f8ff;
      border: solid 1px rgb(2, 136, 209);
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      margin: 5px;
    }

    .textArea {
      border: none;
      width: 100%;
      background: none;
      color: rgb(2, 136, 209);
    }

    .controlsBar {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .fullScreenBtn {
      margin-right: 15px;
      background: none;
      width: 30px;
      cursor: pointer;
    }

    .controlsBarSecond {
      display: flex;
      height: 100%;
      width: 33.3%;
      justify-content: center;
    }

    .controlsBarLast {
      display: flex;
      height: 100%;
      width: 33.3%;
      justify-content: flex-end;
    }
  }
}

//renderinfo
.renderinfo-logo {
  img {
    width: 150px;
  }
}

.renderinfo-title {
  margin-bottom: 20px;
  .h5 {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

//profile-detail
.profile-detail {
  .user-img {
    width: 150px;
    border-radius: 100%;
    border: 1px solid #cecece;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    img {
      width: 100%;
    }
  }
  .user-name {
    display: flex;
    align-items: center;
    height: 100%;
    h1 {
      margin-bottom: 0px;
      font-size: 25px;
      padding: 10px 0px;
    }
  }
}

//modal-artwork
.modal-artwork {
  text-align: center;
  .attributionlink {
    margin-bottom: 10px;
    a {
      text-decoration: none;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  .attributionimg {
    margin-bottom: 10px;
  }
  .attribution-detail {
    p {
      font-size: 20px;
    }
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.8);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 991px) {
  //profile-detail
  .user-name {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  //renderinfo
  .renderinfo-title {
    float: left;
  }
}

.ar-button-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 7%;
  align-items: center;
  justify-content: center;
}

.ar-button {
  border: none;
  border-radius: 7px;
  width: 50%;
  min-height: 35px;
  font-size: 14;
  background-color: #f8f9fa;
  box-shadow: 0px 3px 10px 3px rgba(77, 75, 75, 0.5),
    0px 0px 5px 1px rgba(77, 75, 75, 0.5);
}

.progress-bar-container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 10%;
}

.progress-bar {
  min-width: 200px;
  max-width: 300px;
  height: 15px;
  color: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.25);
  border: 2px solid currentColor;
  border-radius: 10px;
  opacity: 1;
  transition: opacity 0.3s 0.3s;
  background: none;
  margin-bottom: 35px;
}

.update-bar {
  height: 100%;
  background-color: #76a8d3;
  transform-origin: top left;
  border-radius: 8px;
  transform: scaleX(0);
}

.tooltip-container {
  position: absolute;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  bottom: 40px;
  right: 3%;
  z-index: 1000;
}

.tooltip-container .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 13px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  right: 125%;
  top: 5%;
  z-index: 1;
}

.tooltip-container:hover .tooltiptext {
  transition-delay: 1s;
  visibility: visible;
}

.tooltip-container .tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.interaction-prompt-container {
  display: flex;
  justify-content: center;
  padding-right: 5px;
  align-items: center;
  width: 75px;
  height: 75px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 100%;
}

/*Begin CTA Banner */
model-viewer > #cta-banner-container {
  display: none;
}
model-viewer[ar-status='object-placed'] > #cta-banner-container {
  display: flex;
  visibility: visible;
}

.cta-banner-container {
  position: absolute;
  bottom: 20px;
  width: 100vw;
  height: 81px;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.cta-banner-container.hide {
  display: none;
  visibility: hidden;
}

.cta-banner {
  background-color: black;
  width: 90vw;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 0 15px;
}

.cta-banner-button {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  border: none;
  outline: none;
}

.cta-banner-labels {
  font-size: small;
  line-height: 10px;
}

#cta-banner-title {
  font-size: 1.1rem;
}

#cta-banner-description {
  margin-bottom: 0.5em;
}
/*End CTA Banner */
