.app {
  .content,
  .content > div > div {
    height: calc(100vh - 56px);
  }
}

div {
  box-sizing: border-box;
}
